import styled, { css } from 'styled-components';
import { theme, ifProp } from 'styled-tools';
import { FlexRowWrapper } from '../../../components/layout/FlexRowWrapper';

export const SearchBarWrapper = styled(FlexRowWrapper)`
    align-items: center;
    justify-content: center;
    text-align: center;

    border: 1px ${theme('colors.listView.border')} solid;
    border-radius: ${theme('dims.radiuses.largest')};
    height: ${theme('dims.listView.searchWrapperHeight')};
    width: ${theme('dims.input.searchInput.width')};
    overflow: hidden;
    ${ifProp({ collapsable: true }, css`@media (max-width: ${theme('dims.input.searchInput.collapseThreshold')})  {
        width: ${theme('dims.input.searchInput.collapsedWidth')}
    }`)}

    transition 0.2s linear;
    margin-right: ${theme('dims.spacings.default')};
`;
