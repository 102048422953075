import { isNil } from 'ramda';
import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import useTooltip from '../../components/hooks/useTooltip';
import { useTooltipProps } from '../../components/hooks/useTooltipProps';

export const TooltipContent = forwardRef(({ name, children = null }, ref) => {
    useTooltip(ref, name, isNil(children));

    const tooltipPortal = document.getElementById('canvanizer-tooltip');

    const { name: propsName } = useTooltipProps();

    if (propsName !== name || isNil(tooltipPortal)) {
        return null;
    }

    return createPortal(
        <div>
            {children}
        </div>,
        tooltipPortal
    );
});

TooltipContent.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
