import { SERVICE_URLS } from '../../config/config';

export const API_CONFIG = {
    baseURL: SERVICE_URLS.BACKEND_API_ROOT,
    headers: {
        post: {
            'Content-Type': 'application/json'
        },
        get: {
            'Content-Type': 'application/json'
        },
        put: {
            'Content-Type': 'application/json'
        },
        common: {
            // 'Access-Control-Allow-Origin': '*'
        }
    }
};
