export const SERVICE_URLS = {
    BACKEND_API_ROOT: '/backend/api/v1',
    IMAGE_SERVICE_ROOT: '/thumbs',
    PAYMENT_ADDRESS: 'https://canvanizer.com/thenextlevel5/payment.php?package={{package}}',
    UPDATER_WEBSOCKET: '/websocket',
    USE_TLS: true,
    apiUrl(path) {
        return this.BACKEND_API_ROOT + path;
    },
    websocketUrl() {
        let url = this.UPDATER_WEBSOCKET;
        if (/^wss?:\/\//.test(url)) {
            return url;
        }
        const prefix = `ws${this.USE_TLS ? 's' : ''}`;
        if ((/^\/\//.test(url))) {
            // protocol-relative
            url = `${prefix}:${url}`;
        }
        else if (url[0] === '/') {
            // relative
            url = `${prefix}://${window.location.host}${url}`;
        }
        else {
            // we don't even support things like domain.com/path right now;
            // would have to be written as //domain.com/path
            throw new Error(`unsupported UPDATER_WEBSOCKET VALUE: ${this.UPDATER_WEBSOCKET}`);
        }
        return url;
    }
};
