import { Subject } from 'rxjs';

export const tooltipStream$ = new Subject();

class Tooltip {
    constructor() {
        this.visible = false;
        this.position = {
            width: 0,
            height: 0,
            x: 0,
            y: 0
        };
        this.name = null;
    }

    hide() {
        this.visible = false;
        tooltipStream$.next(this.serialize());
    }

    setDims(width, height) {
        this.position = {
            ...this.position,
            width,
            height
        };
        tooltipStream$.next(this.serialize());
    }

    setElement(position, name) {
        this.visible = true;
        this.position = {
            ...this.position,
            ...position
        };
        this.name = name;
        tooltipStream$.next(this.serialize());
    }

    get bottomArrow() {
        return (this.position.y + this.position.height) <= window.innerHeight;
    }

    get styling() {
        return {
            opacity: this.visible ? 1 : 0,
            left: Math.max(0, (this.position.x ?? 0) - ((this.position.width ?? 0) / 2)),
            top: this.bottomArrow ? this.position.y : this.position.y - (this.position.objectHeight ?? 0) - (this.position.height ?? 0) - 16
        };
    }

    get arrowFix() {
        return Math.max(0, (this.position.width ?? 0) / 2 - this.position.x);
    }

    serialize() {
        const {
            visible,
            position,
            name,
            bottomArrow,
            styling,
            arrowFix
        } = this;

        return {
            visible,
            position,
            name,
            bottomArrow,
            styling,
            arrowFix
        };
    }
}

export const tooltipClass = new Tooltip();
