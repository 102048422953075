import React, { memo } from 'react';

export const IconsDefs = memo(() => (
    <defs>
        <path id="settings-full__a" d="M26 26H0V0h26z" />
        <mask id="settings-full__b" fill="#fff">
            <use xlinkHref="#settings-full__a" />
        </mask>
        <path id="delete__a" d="M0 3.225V0h16.514v3.225H0z" />
        <mask id="delete__b" fill="#fff">
            <use xlinkHref="#delete__a" />
        </mask>
        <linearGradient
            id="active-gradient"
            x1="50%"
            x2="50%"
            y1="0%"
            y2="100%"
        >
            <stop offset="0%" stopColor="#00CBFF" />
            <stop offset="100%" stopColor="#009BFF" />
        </linearGradient>
        <filter
            id="checked_icon_a"
            width="152.1%"
            height="169.7%"
            x="-26.1%"
            y="-24.9%"
            filterUnits="objectBoundingBox"
        >
            <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
                stdDeviation={2}
            />
            <feColorMatrix
                in="shadowBlurOuter1"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
            />
        </filter>
        <filter
            id="checked_icon_c"
            width="144.7%"
            height="159.8%"
            x="-22.3%"
            y="-19.9%"
            filterUnits="objectBoundingBox"
        >
            <feGaussianBlur
                in="SourceAlpha"
                result="shadowBlurInner1"
                stdDeviation={1.5}
            />
            <feOffset dy={1} in="shadowBlurInner1" result="shadowOffsetInner1" />
            <feComposite
                in="shadowOffsetInner1"
                in2="SourceAlpha"
                k2={-1}
                k3={1}
                operator="arithmetic"
                result="shadowInnerInner1"
            />
            <feColorMatrix
                in="shadowInnerInner1"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.80225317 0"
            />
        </filter>
        <path
            id="checked_icon_b"
            d="M11.622 13.045L23.377.756a1.609 1.609 0 012.34 0l1.466 1.533a1.791 1.791 0 010 2.446l-12.665 13.24a1.764 1.764 0 01-.2.25l-1.514 1.584a1.662 1.662 0 01-2.417 0l-9.071-8.926a1.85 1.85 0 010-2.527L2.83 6.773a1.662 1.662 0 012.417 0l6.374 6.272z"
        />
        <circle id="unchecked-icon__b" cx={14} cy={11} r={3} />
        <path id="admin__a" d="M0 8.015V0h12.49v8.015z" />
        <mask id="admin__b" fill="#fff">
            <use xlinkHref="#admin__a" />
        </mask>
        <path id="edit__a" d="M0 4.8h3.955V.845H0z" />
        <path id="view__a" d="M12.334 0v7.503H0V0z" />
        <mask id="edit__b" fill="#fff">
            <use xlinkHref="#edit__a" />
        </mask>
        <mask id="delete-part" fill="#fff">
            <path d="M0 3.225V0h16.514v3.225H0z" />
        </mask>
    </defs>
));

export const IconsCollection = memo(() => (
    <div hidden>
        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
            <symbol id="lupe" viewBox="0 0 21 20">
                <path
                    d="M14.127 3.015A7.857 7.857 0 113.016 14.126a7.857 7.857 0 1111.111-11.11zm-9.429 9.43a5.478 5.478 0 010-7.746m14.985 14.984L14.5 14.5"
                    fill="none"
                    fillRule="evenodd"
                    stroke="#979797"
                />
            </symbol>
        </svg>
    </div>
));

