import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { fontFamilies, typeScale } from '../../themes/typography';

export const TooltipWrapper = styled.div`
    @media print {
        display: none !important;
    }

    position: fixed;

    padding: ${theme('dims.spacings.tiny')} ${theme('dims.spacings.small')};
    border-radius: ${theme('dims.spacings.small')};
    background: ${theme('colors.tooltip.background')};
    color: ${theme('colors.tooltip.text')};

    font-size: ${typeScale.smaller};
    font-family: ${fontFamilies.light};

    transform: translateY(${theme('dims.tooltip.arrowHeight')});

    z-index: ${theme('dims.zIndexes.tooltip')};

    pointer-events: none;

    transition: opacity 100ms ease-in-out;

    &:after {
        left: ${({ arrowFix }) => `calc(50% - ${arrowFix}px)`};
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        color: ${theme('colors.tooltip.text')};
        border-left: calc(${theme('dims.tooltip.arrowWidth')} / 2) solid ${theme('colors.tooltip.border')};
        border-right: calc(${theme('dims.tooltip.arrowWidth')} / 2) solid ${theme('colors.tooltip.border')};
        margin-left: calc(-${theme('dims.tooltip.arrowWidth')} / 2);

        ${ifProp('bottomArrow', css`
            bottom: 100%;
            border-bottom: ${theme('dims.tooltip.arrowHeight')} solid ${theme('colors.tooltip.background')};
            border-top: calc(${theme('dims.tooltip.arrowWidth')} / 2) solid ${theme('colors.tooltip.border')};
        `, css`
            top: 100%;
            border-top: ${theme('dims.tooltip.arrowHeight')} solid ${theme('colors.tooltip.background')};
            border-bottom: calc(${theme('dims.tooltip.arrowWidth')} / 2) solid ${theme('colors.tooltip.border')};
        `)};
    }

    &:empty {
        display: none;
    }

    > div {
        display: none
    }

    div:first-child {
        display: block;
    }
`;
