import styled from 'styled-components';
import { theme } from 'styled-tools';

export const ItemThumbnailWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    box-shadow: ${theme('shadows.newCanvasModalThumbnail')};
`;
