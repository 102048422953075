export const typeScale = {
    giant: '8rem',
    great: '4rem',
    colossal: '2.5rem',
    huge: '2.25rem',
    largest: '2rem',
    larger: '1.5rem',
    large: '1.25rem',
    medium: '1.125rem',
    small: '1rem',
    smaller: '0.9375rem',
    smallest: '0.825rem',
    tiny: '0.8125rem',
    microscopic: '0.6875rem',
    atomic: '0.625rem'
};

export const avatarTypeScales = {
    large: '4rem',
    medium: '2rem',
    small: '1.5rem',
    smaller: '1.5rem',
    tiny: '1.25rem'
};

export const fontFamilies = {
    primary: 'LatoLatinWeb',
    light: 'LatoLatinWebLight'
};

export const fontWeights = {
    lighter: 100,
    normal: 400,
    bold: 800
};
