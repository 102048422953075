import styled, { keyframes, css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const animation = css`
    ${rotate}  2s linear infinite;
 `;

export const IconWrapper = styled.svg`
    height: ${prop('size')};
    max-width: ${prop('size')};

    transform: rotate(${prop('rotate', 0)}deg);
    transition: transform 300ms;

    animation: ${ifProp('animateRotation', animation)};

    fill: ${prop('color')};
    stroke: ${prop('stroke')};

    flex-shrink: 0;
    ${ifProp('isButton', 'cursor: pointer;')}
`;

IconWrapper.defaultProps = {
    size: '1em'
};
