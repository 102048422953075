import styled from 'styled-components';
import PropTypes from 'prop-types';
import { prop, ifProp } from 'styled-tools';
import { basicFlexStyles } from './basicFlexStyles';

export const FlexWrapper = styled.div`
    ${basicFlexStyles};
    ${({ backgroundColor, bg }) => backgroundColor || bg ? `background-color: ${backgroundColor ?? bg};` : null}
    ${({ textColor, tc }) => textColor || tc ? `color: ${textColor ?? tc};` : null}
    ${({ boxShadow, sh }) => boxShadow || sh ? `box-shadow: ${boxShadow ?? sh};` : null}
    ${({ backgroundImage }) => backgroundImage ? `background-image: ${backgroundImage};` : null}
    position: ${prop('position', 'unset')};
    width: ${prop('width', 'auto')};
    ${({ minWidth, minW }) => minWidth || minW ? `min-width: ${minWidth ?? minW};` : null}
    ${({ maxWidth, maxW }) => maxWidth || maxW ? `max-width: ${maxWidth ?? maxW};` : null}
    height: ${prop('height', 'auto')};
    ${({ minHeight, minH }) => minHeight || minH ? `min-height: ${minHeight ?? minH};` : null}
    ${({ maxHeight, maxH }) => maxHeight || maxH ? `max-height: ${maxHeight ?? maxH};` : null}
    width: ${ifProp('fullWidth', '100%')};
    height: ${ifProp('fullHeight', '100%')};
    flex-wrap: ${prop('wrap', 'nowrap')};
    flex: ${({ grow, flex }) => grow ? 1 : (flex ?? 'unset')};
    ${({ overflow, ov }) => overflow || ov ? `overflow: ${overflow ?? ov};` : null}
    ${({ overflowX, ox }) => overflowX || ox ? `overflow-x: ${overflowX ?? ox};` : null}
    ${({ overflowY, oy }) => overflowY || oy ? `overflow-y: ${overflowY ?? oy};` : null}

    ${({ margin, m }) => margin || m ? `margin: ${margin ?? m};` : null}
    ${({ marginX, mx }) => marginX || mx
        ? `margin-left: ${marginX ?? mx};
         margin-right: ${marginX ?? mx};`
        : null}
    ${({ marginY, my }) => marginY || my
        ? `margin-top: ${marginY ?? my};
         margin-bottom: ${marginY ?? my};`
        : null}
    ${({ marginLeft, ml }) => marginLeft || ml ? `margin-left: ${marginLeft ?? ml};` : null}
    ${({ marginRight, mr }) => marginRight || mr ? `margin-right: ${marginRight ?? mr};` : null}
    ${({ marginTop, mt }) => marginTop || mt ? `margin-top: ${marginTop ?? mt};` : null}
    ${({ marginBottom, mb }) => marginBottom || mb ? `margin-bottom: ${marginBottom ?? mb};` : null}

    ${({ padding, p }) => padding || p ? `padding: ${padding ?? p};` : null}
    ${({ paddingX, px }) => paddingX || px
        ? `padding-left: ${paddingX ?? px};
         padding-right: ${paddingX ?? px};`
        : null}
    ${({ paddingY, py }) => paddingY || py
        ? `padding-top: ${paddingY ?? py};
         padding-bottom: ${paddingY ?? py};`
        : null}
        
    ${({ paddingLeft, pl }) => paddingLeft || pl ? `padding-left: ${paddingLeft ?? pl};` : null}
    ${({ paddingRight, pr }) => paddingRight || pr ? `padding-right: ${paddingRight ?? pr};` : null}
    ${({ paddingTop, pt }) => paddingTop || pt ? `padding-top: ${paddingTop ?? pt};` : null}
    ${({ paddingBottom, pb }) => paddingBottom || pb ? `padding-bottom: ${paddingBottom ?? pb};` : null}

    ${({ borderWidth, bdw }) => borderWidth || bdw ? `border-width: ${borderWidth ?? bdw};` : null}
    ${({ borderColor, bdc }) => borderColor || bdc ? `border-color: ${borderColor ?? bdc};` : null}
    ${({ borderStyle, bds }) => borderStyle || bds ? `border-style: ${borderStyle ?? bds};` : null}

    ${({ zIndex, z }) => zIndex || z ? `z-index: ${zIndex ?? z};` : null}
    ${({ basis }) => basis ? `flex-basis: ${basis};` : null}
    ${({ flex }) => flex ? `flex: ${flex};` : null}
    ${({ cursor, c }) => cursor || c ? `cursor: ${cursor ?? c};` : null};
    ${({ borderRadius, br }) => borderRadius || br ? `border-radius: ${borderRadius ?? br};` : null}
    ${({ opacity, opc }) => opacity || opc ? `opacity: ${opacity ?? opc};` : null}
    ${({ border, bd }) => border || bd ? `border: ${border ?? bd};` : null}
    ${({ borderX, bx }) => borderX || bx
        ? `border-left: ${borderX ?? bx};
        border-right: ${borderX ?? bx};`
        : null}
    ${({ borderY, by }) => borderY || by
        ? `border-top: ${borderY ?? by};
        border-bottom: ${borderY ?? by};`
        : null}
    ${({ borderLeft, bl }) => borderLeft || bl ? `border-left: ${borderLeft ?? bl};` : null}
    ${({ borderRight, brg }) => borderRight || brg ? `border-right: ${borderRight ?? brg};` : null}
    ${({ borderTop, bt }) => borderTop || bt ? `border-top: ${borderTop ?? bt};` : null}
    ${({ borderBottom, bb }) => borderBottom || bb ? `border-bottom: ${borderBottom ?? bb};` : null}
    ${prop('font')};
    ${({ direction }) => direction ? `flex-direction: ${direction};` : null}
    ${prop('font')};
`;

FlexWrapper.propTypes = {
    overflow: PropTypes.string,
    ov: PropTypes.string,
    overflowX: PropTypes.string,
    ox: PropTypes.string,
    overflowY: PropTypes.string,
    oy: PropTypes.string,
    margin: PropTypes.string,
    m: PropTypes.string,
    marginX: PropTypes.string,
    marginY: PropTypes.string,
    mx: PropTypes.string,
    my: PropTypes.string,
    padding: PropTypes.string,
    p: PropTypes.string,
    paddingX: PropTypes.string,
    paddingY: PropTypes.string,
    px: PropTypes.string,
    py: PropTypes.string,
    backgroundColor: PropTypes.string,
    bg: PropTypes.string,
    contentColor: PropTypes.string,
    ct: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
    wrap: PropTypes.oneOf(['inherit', 'initial', 'nowrap', 'revert', 'unset', 'wrap-reverse', 'wrap']),
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    grow: PropTypes.number,
    basis: PropTypes.string,
    flex: PropTypes.number,
    cursor: PropTypes.string,
    c: PropTypes.string,
    zIndex: PropTypes.string,
    z: PropTypes.string,
    borderWidth: PropTypes.string,
    bdw: PropTypes.string,
    borderColor: PropTypes.string,
    bdc: PropTypes.string,
    opacity: PropTypes.string,
    opc: PropTypes.string,
    border: PropTypes.string,
    bd: PropTypes.string,
    borderX: PropTypes.string,
    bx: PropTypes.string,
    borderY: PropTypes.string,
    by: PropTypes.string,
    borderLeft: PropTypes.string,
    bl: PropTypes.string,
    borderRight: PropTypes.string,
    brg: PropTypes.string,
    borderTop: PropTypes.string,
    bt: PropTypes.string,
    borderBottom: PropTypes.string,
    bb: PropTypes.string,
    textColor: PropTypes.string,
    tc: PropTypes.string,
    boxShadow: PropTypes.string,
    sh: PropTypes.string
};
