import React, { useEffect } from 'react';
import useMeasure from 'react-use-measure';
import { TooltipWrapper } from './Wrapper';
import { tooltipClass } from './TooltipClass';
import { useTooltipProps } from '../../components/hooks/useTooltipProps';

export const Tooltip = () => {
    const [tooltipRef, tooltipBounds] = useMeasure();

    const { bottomArrow, styling, arrowFix } = useTooltipProps();

    useEffect(() => {
        tooltipClass.setDims(tooltipBounds.width, tooltipBounds.height);
    }, [tooltipBounds.width, tooltipBounds.height]);

    return (
        <TooltipWrapper
            id="canvanizer-tooltip"
            ref={tooltipRef}
            style={styling}
            bottomArrow={bottomArrow}
            arrowFix={arrowFix}
        />
    );
};
