import React, { useEffect, useState } from 'react';
import { fromJS, List } from 'immutable';
import {
    includes, set, map, lensProp,
    pipe, filter, sortBy, toLower
} from 'ramda';
import { useTheme } from 'styled-components';
import { TemplateListItem } from './components/ListItem';
import { api } from '../../api/config';
import { CANVAS_MODEL_IDS } from '../../canvas/canvas-definitions/consts';
import { getTemplateThumbnailFilePath } from './helpers';
import { FILTER_CATEGORIES } from './constants';
import { FilterButtonsPanel } from './components/FilterButtonsPanel';
import { SearchBar } from '../ListView/components/SearchBar';
import { FlexRowWrapper } from '../../components/layout/FlexRowWrapper';

export const NewCanvasModal = () => {
    const { dims } = useTheme();

    const openDemoCanvas = (id) => {
        window.location.href = `/go/next/demo/${id}`;
    };

    const [templates, setTemplates] = useState(List());
    const [currentFilterTag, setCurrentFilterTag] = useState(FILTER_CATEGORIES.ALL);
    const [filterQuery, setFilterQuery] = useState('');
    const [fetchedCategories, setFetchedCategories] = useState(null);

    useEffect(() => {
        const serveCategories = (categories) => {
            const canvasIdSet = {}; // dedup canvases for canvases list since they can occur multiple times
            const canvases = [];
            const canvasesCategories = [];

            categories.forEach((category) => {
                canvasesCategories.push(category);
                category.canvasTypes.forEach((canvasInfo) => {
                    if (!canvasIdSet[canvasInfo.id]) {
                        const filename = CANVAS_MODEL_IDS[canvasInfo.id];
                        canvases.push({
                            filename,
                            thumbnailUrl: getTemplateThumbnailFilePath(filename),
                            ...canvasInfo
                        });
                        canvasIdSet[canvasInfo.id] = true;
                    }
                });
            });

            const immutableReducedCategories = fromJS(map(
                (category) => set(lensProp('canvasTypes'), map((template) => template.id, category.canvasTypes), category),
                canvasesCategories
            ));

            setTemplates(canvases);
            setFetchedCategories(immutableReducedCategories);
        };

        api.get('https://backend.canvanizer.com/api/v1/canvases/categories')
            .then(({ data: categories }) => {
                serveCategories(categories);
                localStorage.setItem('categories', JSON.stringify(categories));
            })
            .catch(() => {
                serveCategories(JSON.parse(localStorage.getItem('categories')));
            });
    }, []);

    const filteredTemplates = pipe(
        filter((template) => includes(toLower(filterQuery), toLower(template.name))),
        filter(
            (template) => currentFilterTag === FILTER_CATEGORIES.ALL
                || fetchedCategories
                    .find((category) => includes(currentFilterTag, category.get('name')))
                    .get('canvasTypes')
                    .includes(template.id)
        )
    )(templates);

    const sortedTemplates = sortBy((template) => template.name, filteredTemplates);

    return (
        <React.Fragment>
            <FlexRowWrapper
                my={dims.spacings.small}
            >
                <SearchBar
                    value={filterQuery}
                    callback={(e) => setFilterQuery(e.target.value)}
                />
            </FlexRowWrapper>
            <FilterButtonsPanel
                currentFilterTag={currentFilterTag}
                setCurrentFilterTag={setCurrentFilterTag}
            />
            <TemplateListItem
                listItems={sortedTemplates}
                onClick={openDemoCanvas}
            />
        </React.Fragment>
    );
};
