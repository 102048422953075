import { fontFamilies, fontWeights, typeScale } from './typography';

export const fonts = {
    filterInput: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.smaller};
        font-weight: ${fontWeights.normal};
    `,
    canvasTitleSmall: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.bold};
    `,
    buttonSmall: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.smaller};
        font-weight: ${fontWeights.normal};
    `,
    button: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.bold};
    `,
    buttonInactive: `
        font-family: ${fontFamilies.primary};
        font-size: ${typeScale.medium};
        font-weight: ${fontWeights.normal};
    `
};
