import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { map, values } from 'ramda';
import { FlexRowWrapper } from '../../../components/layout/FlexRowWrapper';
import { PrimaryButton } from '../../../components/buttons/PrimaryButton';
import { FILTER_CATEGORIES } from '../constants';

export const FilterButtonsPanel = ({ currentFilterTag, setCurrentFilterTag }) => {
    const theme = useTheme();
    const onClick = (filterName) => setCurrentFilterTag(filterName);

    return (
        <FlexRowWrapper mb={theme.dims.spacings.default} wrap="wrap">
            {
                map((filterName) => (
                    <PrimaryButton
                        mr={theme.dims.spacings.default}
                        mb={theme.dims.spacings.default}
                        length={filterName === FILTER_CATEGORIES.PROJECT_MANAGEMENT ? 'default' : 'short'}
                        active={filterName === currentFilterTag}
                        key={`button-${filterName}`}
                        onClick={() => onClick(filterName)}
                    >
                        {filterName}
                    </PrimaryButton>
                ), values(FILTER_CATEGORIES))
            }
        </FlexRowWrapper>
    );
};

FilterButtonsPanel.propTypes = {
    currentFilterTag: PropTypes.string.isRequired,
    setCurrentFilterTag: PropTypes.func.isRequired
};
