const colorDefinitions = {
    transparentBlack: {
        0: 'rgba(0,0,0,0)',
        10: 'rgba(0,0,0,0.1)',
        15: 'rgba(0,0,0,0.15)',
        17: 'rgba(0,0,0,0.17)',
        18: 'rgba(0,0,0,0.18)',
        19: 'rgba(0, 0, 0, 0.19)',
        20: 'rgba(0,0,0,0.2)',
        23: 'rgba(0,0,0,0.23)',
        24: 'rgba(0,0,0,0.24)',
        25: 'rgba(0,0,0,0.25)',
        27: 'rgba(0,0,0,0.27)',
        30: 'rgba(0, 0, 0, 0.3)',
        31: 'rgba(0,0,0,0.31)',
        45: 'rgba(0,0,0,0.45)',
        50: 'rgba(0,0,0,0.5)',
        55: 'rgba(0,0,0,0.55)',
        60: 'rgba(0,0,0,0.6)',
        70: 'rgba(0,0,0,0.7)',
        80: 'rgba(0,0,0,0.8)',
        100: 'rgba(0,0,0,1)'
    },
    transparentWhite: {
        0: 'rgba(255,255,255, 0)',
        30: 'rgba(255,255,255,0.3)',
        50: 'rgba(255, 255, 255, 0.5)',
        75: 'rgba(255, 255, 255, 0.75)',
        90: 'rgba(255,255,255,0.9)'
    },
    transparentGrey: {
        0: 'rgba(255, 255, 255, 0)',
        9: 'rgba(93, 93, 93, 0.09)',
        25: 'rgba(143, 143, 143, 0.25)',
        45: 'rgba(151, 151, 151, 0.45)',
        55: 'rgba(151,151,151, 0.55)'
    },
    blue: {
        300: '#004F82',
        400: '#00CBFF',
        425: '#00B1FF',
        490: '#009BC3',
        500: '#009BFF',
        600: '#4a8fe2',
        640: '#4e81e5',
        700: '#5385b5'
    },
    navy: {
        100: '#d3f0f7',
        200: '#e0eaff',
        300: '#bad9f9',
        600: '#4a8fe2'
    },
    green: {
        100: '#ddefb6',
        150: '#afe000',
        200: '#ccfe13',
        300: '#c1e57c',
        400: '#87f361',
        450: '#7ED222',
        500: '#50dd1c',
        550: '#48C619'
    },
    yellow: {
        100: '#ffffc8',
        200: '#ffffa2',
        300: '#fdf67c'
    },
    orange: {
        100: '#ffcf5b',
        400: '#ffce3d'
    },
    red: {
        0: '#FF0000',
        100: '#fdbcae',
        300: '#fa8065',
        500: '#EF5350'
    },
    black: {
        100: '#d0d0d0',
        300: '#b0b0b0'
    },
    grey: {
        0: '#FFFFFF',
        1: '#FEFEFE',
        8: '#f7f7f7',
        9: '#F6F6F6',
        10: '#F5F5F5',
        13: '#F2F2F2',
        20: '#EBEBEB',
        23: '#E6E6E6',
        25: '#D8D8D8',
        27: '#D6D6D6',
        30: '#D3D3D3',
        44: '#CECECE',
        50: '#C8C8C8',
        100: '#BEBEBE',
        120: '#A2A2A2',
        145: '#9B9B9B',
        150: '#979797',
        155: '#969696',
        175: '#929292',
        200: '#888888',
        250: '#808080',
        300: '#717171',
        350: '#666666',
        400: '#5D5D5D',
        450: '#4A4A4A',
        500: '#3C3C3C',
        510: '#393939',
        550: '#323232',
        600: '#292929',
        700: '#1A1A1A',
        900: '#0d000d',
        1000: '#000000'
    },
    turcus: {
        5: '#F2FDFF',
        400: '#83b0be'
    }
};

const opacityDefinitions = {
    100: 'FF',
    55: '8C',
    58: '94',
    50: '80',
    45: '73',
    9: '23',
    0: '00'
};

export const colors = {
    primary: '#4A8FE2',
    primaryInactive: colorDefinitions.grey[150],
    selectedItem: `linear-gradient(to bottom, ${colorDefinitions.blue[400]}, ${colorDefinitions.blue[500]})`,
    backgroundInactive: '#EEEEEE',
    border: colorDefinitions.grey[25],
    positiveAttention: colorDefinitions.green[500],
    attention: '#FFCF5B',
    drastic: '#F3934D',
    sectionBackdrop: '#FAFAFA',
    light: '#FFFFFF',
    lightcoral: '#f08080',
    black: colorDefinitions.grey[1000],
    personal: '#83b0be',
    notify: '#4a90e2',
    vividNotify: colorDefinitions.blue[640],
    focus: colorDefinitions.blue[400],
    error: colorDefinitions.red[500],
    warning: colorDefinitions.orange[400],
    transparent: colorDefinitions.transparentBlack[0],
    modalBackground: colorDefinitions.grey[0],
    moreAvatarsBackground: colorDefinitions.grey[200],
    dangerous: colorDefinitions.red[0],
    positive: colorDefinitions.green[450],
    modalBorderColor: colorDefinitions.grey[50],
    noteHighlight: colorDefinitions.blue[425],
    modalAttention: colorDefinitions.yellow[200],
    text: {
        dark: '#BEBEBE',
        darker: '#717171',
        details: colorDefinitions.grey[450],
        darkest: colorDefinitions.grey[500],
        inkDark: colorDefinitions.grey[900],
        primary: colorDefinitions.grey[400],
        link: '#4a90e2',
        clickableText: colorDefinitions.blue[600],
        notice: '#888888',
        inverted: '#FFFFFF',
        lightParagraph: colorDefinitions.grey[100],
        paragraph: colorDefinitions.grey[400],
        lightHeader: colorDefinitions.grey[400],
        header: colorDefinitions.grey[500],
        primaryInput: colorDefinitions.grey[400],
        placeholder: `${colorDefinitions.grey[150]}${opacityDefinitions[50]}`,
        inactive: colorDefinitions.grey[145],
        progressView: `${colorDefinitions.grey[150]}${opacityDefinitions[55]}`
    },
    scrollbar: {
        background: colorDefinitions.transparentBlack[0],
        thumb: colorDefinitions.transparentBlack[45]
    },
    canvasesPanel: {
        divider: `${colorDefinitions.grey[150]}${opacityDefinitions[50]}`
    },
    tooltip: {
        background: colorDefinitions.grey[600],
        text: colorDefinitions.grey[44],
        border: colorDefinitions.transparentWhite[0]
    },
    headerInput: {
        text: colorDefinitions.grey[400]
    }
};

export const shadows = {
    newCanvasModalThumbnail: `0 2px 4px 0 ${colorDefinitions.transparentBlack[17]}`
};
