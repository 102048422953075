import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { useTheme } from 'styled-components';

import { SearchBarInput } from './SearchBarInput';
import { SEARCH_BAR_PLACEHOLDER } from '../constants';
import { SearchBarWrapper } from './SearchBarWrapper';
import { Icon } from '../../../components/icons/Icon';
import { LupeWrapper } from './LupeWrapper';

export const SearchBar = ({ value, callback }) => {
    const { dims } = useTheme();
    return (
        <SearchBarWrapper>
            <LupeWrapper>
                <Icon iconName="lupe" size={dims.icons.default} />
            </LupeWrapper>
            <SearchBarInput
                value={value}
                onChange={callback}
                placeholder={SEARCH_BAR_PLACEHOLDER}
            />
        </SearchBarWrapper>
    );
};

SearchBar.propTypes = {
    value: PropTypes.string,
    callback: PropTypes.func
};

SearchBar.defaultProps = {
    value: '',
    callback: identity
};
