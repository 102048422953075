const GRID_SIZE = 4;

export const dims = {
    zIndexes: {
        tooltip: 200
    },
    spacings: {
        one: '1px',
        tiny: `${GRID_SIZE}px`,
        small: `${2 * GRID_SIZE}px`,
        large: `${3 * GRID_SIZE}px`,
        default: `${4 * GRID_SIZE}px`,
        larger: `${5 * GRID_SIZE}px`,
        largest: `${6 * GRID_SIZE}px`,
        huge: `${8 * GRID_SIZE}px`,
        colossal: `${10 * GRID_SIZE}px`,
        grand: `${12 * GRID_SIZE}px`,
        magnificent: `${16 * GRID_SIZE}px`,
        giant: `${19 * GRID_SIZE}px`
    },
    radiuses: {
        tiny: '4px',
        smaller: '5px',
        small: '6px',
        medium: '8px',
        big: '10px',
        large: '16px',
        larger: '18px',
        largest: '20px',
        huge: '40px'
    },
    buttons: {
        borderRadius: '48px',
        sizes: {
            small: '32px',
            default: '40px'
        },
        lengths: {
            short: '160px',
            small: '224px',
            default: '300px',
            long: '500px',
            full: '100%'
        },
        addItem: {
            height: '51px'
        }
    },
    icons: {
        tiny: '12px',
        default: '20px',
        small: '24px',
        medium: '28px',
        big: '32px',
        bigger: '40px',
        great: '44px',
        large: '52px',
        larger: '54px',
        sidebar: '55px',
        socialShare: 32
    },
    scrollbar: {
        width: '0.438rem',
        paddingRight: '0.063rem',
        track: {
            width: '0.625rem',
            right: '0rem'
        }
    },
    input: {
        height: '36px',
        searchInput: {
            width: '220px',
            collapsedWidth: '40px',
            collapseThreshold: '1060px'
        }
    },
    listView: {
        arrowSize: '5px',
        searchBarInputHeight: '32px',
        searchWrapperHeight: '40px'
    },
    tooltip: {
        arrowHeight: '0.5rem',
        arrowWidth: '1rem'
    },
    spinnerSize: '100px'
};
