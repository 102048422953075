import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { containsSomething } from '../../utils/ramda';
import { tooltipClass } from '../../canvanizer-ui/tooltip/TooltipClass';

export default function useTooltip(targetElementRef, name, empty) {
    const touched = useRef(false);

    useEffect(() => {
        if (
            isNil(targetElementRef.current)
        ) {
            return () => {};
        }

        const mouseEnterCallback = (e) => {
            e.preventDefault();
            if (!containsSomething(name) || empty || touched.current) {
                return;
            }

            const dims = targetElementRef.current?.getBoundingClientRect();
            const position = {
                x: (dims?.x ?? 0) + (dims?.width ?? 0) / 2,
                y: (dims?.y ?? 0) + (dims?.height ?? 0),
                objectHeight: dims?.height
            };

            tooltipClass.setElement(position, name);
        };

        const mouseLeaveCallback = () => {
            if (isNil(name)) {
                return;
            }
            tooltipClass.hide();
        };

        const touchEnterCallback = () => {
            touched.current = true;
        };

        const touchLeaveCallback = () => {
            touched.current = true;
            setTimeout(() => {
                touched.current = false;
            }, 50);
        };

        targetElementRef.current?.addEventListener('touchstart', touchEnterCallback);
        targetElementRef.current?.addEventListener('mousemove', mouseEnterCallback);
        targetElementRef.current?.addEventListener('mouseleave', mouseLeaveCallback);
        document.addEventListener('touchend', touchLeaveCallback);

        return () => {
            targetElementRef.current?.removeEventListener('touchstart', touchEnterCallback);
            targetElementRef.current?.removeEventListener('mousemove', mouseEnterCallback);
            targetElementRef.current?.removeEventListener('mouseleave', mouseLeaveCallback);
            document.removeEventListener('touchend', touchLeaveCallback);
        };
    }, [targetElementRef.current, name, empty]);

    useEffect(() => () => {
        if (name === tooltipClass.name) {
            tooltipClass.hide();
        }
    }, []);
}
