import { css } from 'styled-components';
import { ifProp, theme, prop } from 'styled-tools';

export const applyFont = (font) => css`
    ${theme(`fonts.${font}`)};
    
    margin-block-start: 0;
    margin-block-end: 0;
    
    ${ifProp('oneLine', `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `)};

    text-align: ${ifProp('center', 'center', 'left')};
    ${ifProp('full', 'width: 100%')};
    width: ${prop('width', 'unset')};
`;
