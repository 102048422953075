import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
    ifProp, theme, switchProp, prop
} from 'styled-tools';
import { values, isEmpty } from 'ramda';
import { applyFont } from '../typography/fontUtils';

const PrimaryButtonStyle = css`
    border-radius: ${theme('dims.buttons.borderRadius')};
    cursor: ${ifProp('disabled',
        'not-allowed',
        'pointer'
    )};
    border: none;
    outline: none;
    
    ${ifProp('small',
        applyFont('buttonSmall'),
        applyFont('button')
    )};
    
    ${applyFont('button')};
  
    background-color: ${ifProp('disabled',
        theme('colors.primaryInactive'),
        ifProp('active',
            ifProp('dangerous',
                theme('colors.dangerous'),
                ifProp('positive',
                    theme('colors.positive'),
                    theme('colors.primary')
                )
            ),
            theme('colors.primaryInactive')
        )
    )};

    color: ${theme('colors.text.inverted')};

    ${switchProp('active', {
        true: applyFont('button'),
        false: applyFont('buttonInactive')
    })};

    ${ifProp('inverted', css`
        background-color: ${theme('colors.light')};
        border: solid 2px ${theme('colors.primary')};
        color: ${theme('colors.primary')};
    `)};

    text-align: center;
 
    height: ${ifProp('small',
        theme('dims.buttons.sizes.small'),
        theme('dims.buttons.sizes.default')
    )};

    min-height: ${ifProp('small',
        theme('dims.buttons.sizes.small'),
        theme('dims.buttons.sizes.default')
    )};

    width: ${ifProp('length',
        ({ length }) => theme(`dims.buttons.lengths.${length}`),
        theme('dims.buttons.lengths.default')
    )};
    
    ${ifProp('href', 'display: inline-block')};

    margin: ${prop('margin', 0)};
    margin-top: ${prop('mt', 0)};
    margin-bottom: ${prop('mb', 0)};
    margin-left: ${prop('ml', 0)};
    margin-right: ${prop('mr', 0)};

    background-color: ${ifProp('dangerous', theme('colors.dangerous'))};
    
    background-color: ${ifProp('positive',
        theme('colors.positive')
    )};

    background-color: ${ifProp('disabled',
        theme('colors.primaryInactive')
    )};
    
    padding: 0 ${theme('dims.spacings.default')};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: ${ifProp('sizeUnset', css`
        width: unset;
        height: unset;
        flex: 1;
    `)};
`;

const PrimaryButtonTemplate = styled.button`${PrimaryButtonStyle}`;

export const PrimaryButton = (props) => {
    const { href, children } = props;
    return (
        <PrimaryButtonTemplate as={isEmpty(href) ? 'button' : 'a'} {...props}>
            {children}
        </PrimaryButtonTemplate>
    );
};

export const PrimaryButtonLengthTypes = {
    shortest: 'shortest',
    short: 'short',
    small: 'small',
    default: 'default',
    long: 'long',
    full: 'full'
};

export const useButtonLength = (textOptions, sizeBorders) => {
    const [buttonLength, setButtonLength] = useState(sizeBorders.default);
    const [buttonText, setButtonText] = useState(textOptions.default);

    useEffect(() => {
        if (window.innerWidth < sizeBorders.shortest) {
            setButtonText(textOptions.shortest);
            setButtonLength(PrimaryButtonLengthTypes.shortest);
        }
        else if (window.innerWidth < sizeBorders.short) {
            setButtonText(textOptions.short);
            setButtonLength(PrimaryButtonLengthTypes.short);
        }
        else {
            setButtonText(textOptions.default);
            setButtonLength(PrimaryButtonLengthTypes.default);
        }
    }, [window.innerWidth]);

    return [buttonLength, buttonText];
};

PrimaryButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    length: PropTypes.oneOf(values(PrimaryButtonLengthTypes)),
    href: PropTypes.string,
    active: PropTypes.bool,
    modal: PropTypes.bool,
    dangerous: PropTypes.bool,
    positive: PropTypes.bool,
    sizeUnset: PropTypes.bool
};

PrimaryButton.defaultProps = {
    children: null,
    disabled: false,
    small: false,
    active: true,
    length: PrimaryButtonLengthTypes.default,
    href: '',
    modal: false,
    dangerous: false,
    positive: false,
    sizeUnset: false
};
