import { ThemeProvider } from 'styled-components';
import { NewCanvasModal } from './app/containers/NewCanvasModal';
import './assets/fonts/fonts.css';
import { theme } from './app/themes';
import { IconsCollection } from './app/components/icons/IconsCollection';
import { Tooltip } from './app/canvanizer-ui/tooltip/Tooltip';

const App = () => (
    <div className="App">
        <ThemeProvider theme={theme}>
            <IconsCollection />
            <Tooltip />
            <NewCanvasModal />
        </ThemeProvider>
    </div>
);

export default App;
