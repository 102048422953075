import { useEffect, useState } from 'react';
import { tooltipClass, tooltipStream$ } from '../../canvanizer-ui/tooltip/TooltipClass';

export const useTooltipProps = () => {
    const [props, setProps] = useState(tooltipClass.serialize());

    useEffect(() => {
        tooltipStream$.subscribe((newProps) => {
            setProps(newProps);
        });
    }, []);

    return props;
};
