import styled from 'styled-components';
import { theme } from 'styled-tools';

export const SearchBarInput = styled.input.attrs({ type: 'search' })`
    ${theme('fonts.filterInput')};
    border: none !important;
    padding: 0;
    margin-right: ${theme('dims.spacings.small')} !important;

    width: 100%;
    height: ${theme('dims.listView.searchBarInputHeight')};

    color: ${theme('colors.text.darker')};
    text-align: left;

    &:focus {
        outline: none;
    }   

    ::placeholder {
        color: ${theme('colors.text.darker')};
    }
`;
