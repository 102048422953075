import { colors, shadows } from './colors';
import { dims } from './dims';
import { fonts } from './fonts';
import { typeScale, fontFamilies, fontWeights } from './typography';

export const theme = {
    colors,
    shadows,
    dims,
    fonts,
    typeScale,
    fontFamilies,
    fontWeights
};
