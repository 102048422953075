import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { min } from 'ramda';
import { ItemWrapper } from './ItemWrapper';
import { ItemHeader } from './ItemHeader';
import { ItemThumbnail } from './ItemThumbnail';
import { ItemThumbnailWrapper } from './ItemThumbnailWrapper';
import { TooltipContent } from '../../../../canvanizer-ui/tooltip/TooltipContent';

export const Item = ({
    name,
    thumbnailUrl,
    onClick,
    wrapperSize
}) => {
    const ref = useRef();
    const [thumbnailShrink, setThumbnailShrink] = useState(min(window.innerWidth / 650, 1));

    useEffect(() => {
        setThumbnailShrink(min(window.innerWidth / 650, 1));
    }, [window.innerWidth]);

    return (
        <React.Fragment>
            <TooltipContent ref={ref} name={`Create ${name}`}>{`Create ${name}`}</TooltipContent>
            <ItemWrapper
                ref={ref}
                numberOfItems={3}
                onClick={onClick}
                wrapperSize={wrapperSize}
                thumbnailShrink={thumbnailShrink}
            >
                <ItemHeader>
                    {name}
                </ItemHeader>
                <ItemThumbnailWrapper>
                    <ItemThumbnail src={thumbnailUrl} alt={`Create ${name}`} />
                </ItemThumbnailWrapper>
            </ItemWrapper>
        </React.Fragment>
    );
};
Item.propTypes = {
    name: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    wrapperSize: PropTypes.any.isRequired
};

Item.defaultProps = {
};
