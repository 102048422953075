import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

export const basicFlexStyles = css`
    display: flex;
    flex-direction: ${prop('direction', 'unset')};
    justify-content: ${prop('justify', 'unset')};
    align-items: ${prop('align', 'unset')};
    flex-grow: ${ifProp('grow', 1)};
    flex-shrink: ${ifProp('grow', 1)};
    flex-wrap: ${prop('wrap', 'nowrap')};
`;
