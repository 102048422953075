import {
    isEmpty, not, isNil,
    allPass, compose,
    map, mergeAll
} from 'ramda';

export const keyBy = (objectProp, array) => mergeAll(map((object) => ({
    [object[objectProp]]: object
}), array));

export const containsSomething = allPass([compose(not, isNil), compose(not, isEmpty)]);
