import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { IconWrapper } from './IconWrapper';
import { IconsDefs } from './IconsCollection';

const DIRECTIONS_TO_ANGLES_DICT = {
    up: 0,
    down: 180,
    right: 90,
    left: 270
};

export const Icon = ({
    iconName, size, rotate, animateRotation, color, stroke, direction, onClick, isButton, style
}) => (
    <IconWrapper
        size={size}
        rotate={isNil(direction) ? rotate : DIRECTIONS_TO_ANGLES_DICT[direction]}
        animateRotation={animateRotation}
        color={color}
        stroke={stroke}
        onClick={onClick}
        isButton={isButton}
        style={style}
    >
        <IconsDefs />
        <use xlinkHref={`#${iconName}`} />
    </IconWrapper>
);

Icon.propTypes = {
    iconName: PropTypes.string.isRequired,
    size: PropTypes.string,
    rotate: PropTypes.number,
    animateRotation: PropTypes.bool,
    color: PropTypes.string,
    stroke: PropTypes.string,
    direction: PropTypes.string,
    onClick: PropTypes.func,
    isButton: PropTypes.bool,
    style: PropTypes.instanceOf(Object)
};

Icon.defaultProps = {
    size: '1em',
    rotate: 0,
    animateRotation: false,
    color: undefined,
    stroke: undefined,
    direction: undefined,
    onClick: () => {},
    isButton: false,
    style: {}
};
